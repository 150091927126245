import * as React from 'react';

import { useFrame } from '../../../lib/frame-react';

import { VehicleDeviceId } from '../../../types/stein';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FilterIcon } from '../../FilterBar/FilterIcon';
import { LoggerToken } from '../../../lib/frame-tokens';
import { EventTypeId, EVENT_TYPE_MAP_ID } from '../../../constants/event-type-constants';
import {
    INFRACTION_VISUAL,
    INFRACTION_HEARING,
    INFRACTION_HANDS_OFF,
    INFRACTION_NOTE_TAKER,
    INFRACTION_PERSONAL_DEVICE,
    INFRACTION_OTHER,
} from '../../../constants/event-infraction-constants';
import { EVENT_ANALYSIS } from '../../../constants/event-analysis-constants';
import { VehicleChip } from '../../AppChip';
import { formatDate, formatDateShort, getTimezoneStr } from '../../../utils/datetime-utils';
import { processDataGridCollums } from '../../DataGrid';
import { Box, Tooltip } from '@mui/material';
import { TooltipContent } from '../../TooltipContent';

// TODO remove ignore Cannot test inside DataGrid, wait for: https://github.com/mui-org/material-ui-x/issues/1151
/* istanbul ignore file */

function InfractonCell({ row: event }: GridRenderCellParams): React.ReactElement {
    const infractions = [];
    if (event.flagVisualObstruction) {
        const infraction = INFRACTION_VISUAL;
        infractions.push(<FilterIcon value={infraction} key={infraction.id} />);
    }
    if (event.flagHearingObstruction) {
        const infraction = INFRACTION_HEARING;
        infractions.push(<FilterIcon value={infraction} key={infraction.id} />);
    }
    if (event.flagHandsOffWheel) {
        const infraction = INFRACTION_HANDS_OFF;
        infractions.push(<FilterIcon value={infraction} key={infraction.id} />);
    }
    if (event.flagNoteTaker) {
        const infraction = INFRACTION_NOTE_TAKER;
        infractions.push(<FilterIcon value={infraction} key={infraction.id} />);
    }
    if (event.flagPersonalDevice) {
        const infraction = INFRACTION_PERSONAL_DEVICE;
        infractions.push(<FilterIcon value={infraction} key={infraction.id} />);
    }
    if (event.flagOtherNotes) {
        const infraction = INFRACTION_OTHER;
        infractions.push(<FilterIcon value={infraction} key={infraction.id} />);
    }
    return <>{infractions}</>;
}

function SpeedCell({ value }: GridRenderCellParams): React.ReactElement {
    return <>{Math.round(value as number)}</>;
}

function VehicleCell({ value }: GridRenderCellParams): React.ReactElement | null {
    const vehicleDeviceId = value as VehicleDeviceId;
    return (
        <Tooltip
            placement="top"
            title={
                <TooltipContent title="Show Vehicle Details">
                    {'To see the event details, click elsewhere in the row'}
                </TooltipContent>
            }
        >
            <div>
                <VehicleChip vehicleSlugOrId={vehicleDeviceId} size={'small'} tabIndex={-1} />
            </div>
        </Tooltip>
    );
}

function AnalysisCell({ value }: GridRenderCellParams): React.ReactElement | null {
    const a = EVENT_ANALYSIS.find((a) => a.id === value);
    if (a) {
        return (
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <FilterIcon value={a} />
            </Box>
        );
    }
    return null;
}

function DateTimeCell({ value }: GridRenderCellParams): string {
    return formatDate(value as string);
}

function DateTimeShortCell({ value }: GridRenderCellParams): string {
    return formatDateShort(value as string);
}

function EventCell({ value }: GridRenderCellParams): React.ReactElement | string | null {
    const eventName = value as EventTypeId;
    const log = useFrame(LoggerToken);
    const event = EVENT_TYPE_MAP_ID[eventName];
    if (!event) {
        log.error('[DriverEventList] no EVENT_TYPES for value:', { value });
        return null;
    }

    return (
        <>
            <FilterIcon value={event} showName />
        </>
    );
}

const DRIVER_EVENT_COLUMNS: GridColDef[] = processDataGridCollums([
    {
        field: 'vehicleDeviceId',
        headerName: 'Vehicle',
        renderCell: VehicleCell,
        minWidth: 175,
        maxWidth: 175,
    },
    {
        field: 'eventName',
        headerName: 'Event',
        minWidth: 200,
        width: 200,
        renderCell: EventCell,
    },
    {
        field: 'analysis',
        headerName: 'Analysis',
        minWidth: 125,
        width: 125,
        renderCell: AnalysisCell,
    },
    {
        field: 'speed',
        headerName: 'Speed',
        renderCell: SpeedCell,
        minWidth: 70,
        width: 70,
    },
    {
        field: 'infractions',
        headerName: 'Infractions',
        renderCell: InfractonCell,
        minWidth: 130,
        width: 130,
    },
    {
        field: 'processedAt',
        headerName: `Processed at (${getTimezoneStr()})`,
        renderCell: DateTimeCell,
        minWidth: 210,
        width: 210,
    },
]);

const DRIVER_EVENT_COLUMNS_SHORT: GridColDef[] = processDataGridCollums([
    {
        field: 'vehicleDeviceId',
        headerName: 'Vehicle',
        renderCell: VehicleCell,
        minWidth: 175,
        maxWidth: 175,
    },
    {
        field: 'eventName',
        headerName: 'Event',
        minWidth: 200,
        width: 200,
        renderCell: EventCell,
    },
    {
        field: 'analysis',
        headerName: 'An...',
        minWidth: 75,
        width: 75,
        renderCell: AnalysisCell,
    },
    {
        field: 'processedAt',
        headerName: `Pro... (${getTimezoneStr()})`,
        renderCell: DateTimeShortCell,
        minWidth: 150,
        width: 150,
    },
]);

export function useDriverEventColumns(short: boolean = false): GridColDef[] {
    return short ? DRIVER_EVENT_COLUMNS_SHORT : DRIVER_EVENT_COLUMNS;
}
