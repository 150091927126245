import { createAction } from '@reduxjs/toolkit';
import {
    User,
    AbilityConfig,
    CustomRole,
    Project,
    Region,
    VehicleDevice,
    DriverEvent,
    Geofence,
    ActionCableDriverEvent,
    ConfigItemConfig,
    NotifConfig,
    SoundEffectId,
    SoundEffect,
    VehicleModel,
    ActionCableVideoCall,
} from '../../types/stein';
import { Location } from 'history';
import { ConfigItem } from '../../types/stein.config_items';

// Define a type for the slice state
export type RailsContext = {
    projects: Array<Project>;
    regions: Array<Region>;
    vehicles: Array<VehicleDevice>;
    driverEvents: Array<DriverEvent>;
    user: User;
    debugEnabled: boolean;
    customRoles: Array<CustomRole>;
    abilityConfig: AbilityConfig;
    configItemsConfig: ConfigItemConfig;
    configItems: Array<ConfigItem>;
    notificationConfig: NotifConfig[];
    geofences: Array<Geofence>;
    soundEffects: Array<SoundEffect>;
    defaultSoundEffectId: SoundEffectId;
    vehicleModels: Array<VehicleModel>;
};

export type RouterLocation = Pick<Location, 'pathname' | 'hash' | 'search' | 'key'>;
export const setRailsContext = createAction<RailsContext>('setRailsContext');

// Define a type for the slice state
type ChangeLocationAction = {
    old?: RouterLocation;
    new: RouterLocation;
};

export const changeLocation = createAction<ChangeLocationAction>('changeLocation');

export const actionCableDriverEvent = createAction<ActionCableDriverEvent>('actionCableDriverEvent');
export const actionCableIncomingCall = createAction<ActionCableVideoCall>('actionCableIncomingCall');
