import { FrameResolver } from '../lib/frame';
import { AppReduxStoreToken } from '../store';
import { ActionCableToken, LoggerToken } from '../lib/frame-tokens';
import { actionCableDriverEvent, actionCableIncomingCall, setIsConnected } from '../store/actions';
import { ActionCableDriverEvent, ActionCableEvent } from '../types/stein';
import { SnakeCasedProperties } from 'type-fest';
import { toCamelCase } from '../lib/case-transforms';
import { EVENT_TYPE_MAP_ID } from '../constants/event-type-constants';

type RawEvent = SnakeCasedProperties<ActionCableDriverEvent>;

export async function startActionCableSubscription({ resolve }: FrameResolver): Promise<void> {
    const log = resolve(LoggerToken);
    const store = resolve(AppReduxStoreToken);
    const cable = resolve(ActionCableToken);

    cable?.subscriptions.create('DashboardNotificationsChannel', {
        received: function (rawData: RawEvent) {
            const event = toCamelCase(rawData) as ActionCableEvent;

            switch (event.type) {
                case 'driver_event':
                    if (!EVENT_TYPE_MAP_ID[event.data.eventName]) {
                        log.error('received actioncable event for unknown eventName', event);
                        return;
                    }

                    store.dispatch(actionCableDriverEvent(event));
                    break;
                case 'incoming_call':
                    store.dispatch(actionCableIncomingCall(event));
            }
        },
        initialized: function () {
            log.debug('initialized');
        },
        connected: function () {
            store.dispatch(setIsConnected(true));
        },
        disconnected: function () {
            store.dispatch(setIsConnected(false));
        },
        rejected: function () {
            log.debug('rejected');
            store.dispatch(setIsConnected(false));
        },
    });
}
