import * as React from 'react';

import { ListItemText } from '@mui/material';
import { FilterIcon } from '../../FilterBar/FilterIcon';

import { VehicleChip } from '../../AppChip';

import { NotificationItemWrapper } from './internal/NotificationItemWrapper';
import { EVENT_TYPE_MAP_ID } from '../../../constants/event-type-constants';
import { AppNotificationTypeEvent } from '../notificationTypes';
import { useAppSelector } from '../../../hooks/hooks';
import { selectProjectById } from '../../../store/selectors';

type NotificationItemEventProps = {
    notification: AppNotificationTypeEvent;
};

export function NotificationItemEvent({ notification }: NotificationItemEventProps): React.ReactElement {
    const event = notification.event;
    const project = useAppSelector((s) => selectProjectById(s, event.projectId));
    const eventValue = EVENT_TYPE_MAP_ID[event.data.eventName];

    return (
        <NotificationItemWrapper
            dismissable
            autoDismissMs={3000}
            icon={<FilterIcon value={eventValue} />}
            project={project}
            url={notification.url}
        >
            <div>
                <ListItemText primary={`New ${eventValue.name} Event`} />
                {event.data.vehicleDeviceId ? (
                    <VehicleChip vehicleSlugOrId={event.data.vehicleDeviceId} size={'small'} />
                ) : /* istanbul ignore next */ null}
            </div>
        </NotificationItemWrapper>
    );
}
