import { createPlugin, createToken } from '../../lib/frame';
import { AppReduxStoreToken } from '../../store';
import { LoggerToken } from '../../lib/frame-tokens';
import {
    createIntercomEmitter,
    TwilioAudioDevice,
    IntercomService,
    TwilioVideoConnect,
    IntercomCallOpts,
} from './intercom-types';
import { SteinInternalApiToken } from '../../clients/stein-internal-api';
import {
    createGetCallCredentialsFn,
    createInitialzieIntercomCallFn,
    createJoinIntercomFn,
} from './internal/create-start-intercom-fn';
import { AnalyticsToken } from '../../lib/frame-analytics';
import { VehicleDevice, VideoCallId } from '../../types/stein';

export const IntercomServiceToken = createToken<IntercomService>('IntercomServiceToken');

export const TwilioVideoConnectToken = createToken<TwilioVideoConnect>('TwilioVideoConnectToken');
export const TwilioAudioDeviceToken = createToken<TwilioAudioDevice>('TwilioAudioDeviceToken');

export const IntercomServicePlugin = createPlugin<IntercomService>(({ resolve }) => {
    const stein = resolve(SteinInternalApiToken);
    const store = resolve(AppReduxStoreToken);
    const log = resolve(LoggerToken);
    const emitter = createIntercomEmitter();
    const connect = resolve(TwilioVideoConnectToken);
    const AudioDevice = resolve(TwilioAudioDeviceToken);
    const analytics = resolve(AnalyticsToken);
    const deps = {
        stein,
        store,
        log,
        emitter,
        connect,
        AudioDevice,
        analytics,
    };

    const _joinCall = createJoinIntercomFn(deps);
    const initCall = createInitialzieIntercomCallFn(deps);
    const fetchCredentials = createGetCallCredentialsFn(deps);

    async function startCall(v: VehicleDevice, o: IntercomCallOpts): Promise<void> {
        const opts = await initCall(v);
        if (opts) {
            return await _joinCall(opts, o);
        }
    }

    async function joinCall(id: VideoCallId): Promise<void> {
        const opts = await fetchCredentials(id);
        if (opts) {
            return await _joinCall(opts, { joinVideo: false, broadcastAudio: true });
        }
    }

    return {
        startCall,
        joinCall,
        emitter,
    };
});
