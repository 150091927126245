import { selectConfigItems } from '../store/selectors';
import { ConfigItemConfigItem, ProjectId } from '../types/stein';
import { ConfigItemKey, ConfigItemForKey, ConfigItem } from '../types/stein.config_items';
import { useAppSelector } from './hooks';
import { useActiveProjectOptional } from './use-active-project';

export function findConfigItem<T extends ConfigItemKey>(
    configItems: ConfigItem[],
    key: T,
): ConfigItemForKey[T] | undefined {
    return configItems.find((c) => c.attributeKey === key) as ConfigItemForKey[T];
}

export function useConfigItemConfig(): <T extends ConfigItemKey>(key: T) => ConfigItemConfigItem {
    const config = useAppSelector((s) => s.configItems.config);
    return function lookupConfigItem<T extends ConfigItemKey>(key: T): ConfigItemConfigItem {
        return config.find((c) => c.key === key) as ConfigItemConfigItem;
    };
}

export function configItemFind<T extends ConfigItemKey>(
    items: ConfigItem[],
    key: T,
    projectId?: ProjectId,
): ConfigItemForKey[T] | undefined {
    return items.find(
        (ci) => ci.attributeKey === key && (!projectId || !ci.projectId || ci.projectId === projectId),
    ) as ConfigItemForKey[T] | undefined;
}

export function useConfigItems(): <T extends ConfigItemKey>(
    key: T,
) => (ConfigItemForKey[T] & { name: string }) | undefined {
    const items = useAppSelector(selectConfigItems);
    const project = useActiveProjectOptional();
    const getConfig = useConfigItemConfig();
    return function lookupConfigItem<T extends ConfigItemKey>(
        key: T,
    ): (ConfigItemForKey[T] & { name: string }) | undefined {
        const projectId = project?.id;
        const config = getConfig(key);
        if (!config) {
            return undefined;
        }
        const item = configItemFind(items, key, projectId);
        if (item) {
            return {
                ...item,
                name: config.name,
            };
        }
    };
}
