import { ThemeOptions } from '@mui/material';

export const DefaultTheme = {
    palette: {
        primary: '#21c5dd',
        secondary: '#253842',
        error: '#A30B37',
        warning: '#FA824C',
        info: '#2E5EAA',
        success: '#C5EDAC',
    },
    colors: {
        white: '#E9ECEF',
        gold: '#EE0',
        icon: {
            logo: '#21c5dd',
            text: '#E9ECEF',
        },
        appbar: {
            background: '#253842',
            text: '#E9ECEF',
        },
        toolbar: {
            background: '#253842',
            text: '#E9ECEF',
        },
        page: {
            background: '#E9ECEF',
            text: '#253842',
        },
        debugBlock: {
            dev: {
                background: '#FDD',
                border: '#A30B37',
            },
            auth: {
                background: '#FFE6C2',
                border: '#FFC370',
            },
            feature: {
                background: '#c8e2f9',
                border: '#5aa8ed',
            },
        },
        datagrid: {
            success: '#C5EDAC',
            selected: '#c8e2f9',
            focus: '#EDF5FD',
            error: '#FDD',
            pending: '#FFE6C2',
            disabled: '#253842',
        },
    },
    fontFamily: 'Montserrat',
    components: {
        // Name of the component
        MuiMenuItem: {
            styleOverrides: {
                // Name of the slot
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#95E3EF',
                    },
                },
            },
        },
    } as ThemeOptions['components'],
};
