import * as React from 'react';
import { DriverEvent, DriverEventCause } from '../../../types/stein';

import { EventTypeId, EVENT_TYPES, EVENT_TYPE_MAP_ID } from '../../../constants/event-type-constants';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { FilterIcon } from '../../FilterBar/FilterIcon';
import { AppIconEdit } from '../../AppIcons';
import { useUpdateDriverEvent } from '../../../hooks/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/system';
import { toLower } from 'lodash';
import { formatLocalizedDateTime } from '../../../utils/datetime-utils';
import { formatDistanceToNow } from 'date-fns';

type DriverEventTypeHeaderProps = {
    event: DriverEvent | undefined;
};

function causeToString(cause: DriverEventCause | null | undefined): string | null {
    switch (cause) {
        // istanbul ignore next
        case 'acceleration':
            // istanbul ignore next
            return 'acceleration';
        // istanbul ignore next
        case 'brake':
            // istanbul ignore next
            return 'braking';
        // istanbul ignore next
        case 'turn':
            // istanbul ignore next
            return 'turning';
        // istanbul ignore next
        case 'crash':
            // istanbul ignore next
            return 'a crash';
    }
    // istanbul ignore next
    return null;
}

export function DriverEventTypeHeader({ event }: DriverEventTypeHeaderProps): React.ReactElement {
    const [edit, setEdit] = React.useState(false);

    const eventConfig = event ? EVENT_TYPE_MAP_ID[event.eventName] : null;

    if (!event || !eventConfig) {
        return (
            <Typography variant="h6" gutterBottom component="div">
                <Skeleton />
            </Typography>
        );
    }

    const cause = causeToString(event.cause);
    const processedAt = event?.processedAt ? new Date(event.processedAt) : /* istanbul ignore next */ null;

    return (
        <Stack>
            <Stack direction={'row'} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom component="div" sx={{ flex: 2 }}>
                    <FilterIcon value={eventConfig} showName />
                </Typography>
                <IconButton size={'small'} sx={{ flex: 0 }} onClick={() => setEdit(true)}>
                    <AppIconEdit />
                </IconButton>
            </Stack>
            {cause ? (
                <Box sx={{ backgroundColor: '#E9ECEF', borderRadius: '5px', padding: 2 }}>{`This ${toLower(
                    eventConfig.name,
                )} event was caused by ${cause}.`}</Box>
            ) : /* istanbul ignore next */ null}
            <SelectDriverEventTypeModal event={event} open={edit} onClose={() => setEdit(false)} />
            {processedAt ? (
                <Typography variant={'caption'} display={'block'} sx={{ marginTop: '0px' }} color={'grey'}>
                    {`${formatLocalizedDateTime(processedAt)} (${formatDistanceToNow(processedAt, { addSuffix: true })})`}
                </Typography>
            ) : /* istanbul ignore next */ null}
        </Stack>
    );
}

type SelectDriverEventTypeModalProps = {
    event: DriverEvent;
    open: boolean;
    onClose: () => void;
};

function SelectDriverEventTypeModal({ event, open, onClose }: SelectDriverEventTypeModalProps): React.ReactElement {
    const [
        updateDriverEvent, // This is the mutation trigger
        { isLoading }, // This is the destructured mutation result
    ] = useUpdateDriverEvent();

    function save(eventName: EventTypeId): void {
        if (event.eventName !== eventName) {
            updateDriverEvent({
                id: event.id,
                eventName,
            })
                .unwrap()
                .then(onClose);
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{'Set Event Type'}</DialogTitle>
            <DialogContent>
                {EVENT_TYPES.map((t) => (
                    <LoadingButton fullWidth onClick={() => save(t.id)} loading={isLoading} key={t.id}>
                        <FilterIcon value={t} showName />
                    </LoadingButton>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{'Cancel'}</Button>
            </DialogActions>
        </Dialog>
    );
}
