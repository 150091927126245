import { createPlugin, createToken } from '../../lib/frame';
import { createListenerMiddleware, ListenerMiddleware, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { SteinInternalApiToken } from '../../clients/stein-internal-api';

import { AppReduxStoreToken } from '../../store';
import { SFXToken } from '../app-sfx-plugin';
import { LoggerToken } from '../../lib/frame-tokens';
import { notificationListenerPush } from './notificaiton-listener-push';
import { notificationListenerPushMulti } from './notificaiton-listener-pushmulti';
import { notificationListenerDriverEvent } from './notification-listener-driver-event';
import { NotificationListenerDeps } from './notification-listener-types';
import { notificationListenerArchive } from './notificaiton-listener-archive';
import { notificationListenerRequests } from './notification-listener-request';
import { notificationListenerVideoCall } from './notification-listener-video-call';

type NotificationListener = ListenerMiddleware<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>;
export const NotificationListenerToken = createToken<NotificationListener>('NotificationListenerToken');

const listeners: Array<(a: AnyAction, d: NotificationListenerDeps) => void> = [
    notificationListenerPush,
    notificationListenerPushMulti,
    notificationListenerDriverEvent,
    notificationListenerArchive,
    notificationListenerRequests,
    notificationListenerVideoCall,
];

export const NotificationListenerPlugin = createPlugin<NotificationListener>(({ resolve }) => {
    const steinInternalApi = resolve(SteinInternalApiToken);
    const notificaitonListener = createListenerMiddleware();
    const effects = resolve(SFXToken);
    const log = resolve(LoggerToken);

    notificaitonListener.startListening({
        predicate: (action) => {
            return typeof action?.type === 'string';
        },

        effect: async (action) => {
            const deps = {
                steinInternalApi,
                store: resolve(AppReduxStoreToken),
                sfx: effects,
                log,
            };

            listeners.forEach((fn) => fn(action, deps));
        },
    });

    return notificaitonListener.middleware;
});
