// istanbul ignore file
import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppDispatch } from '../../../hooks/hooks';
import { useFrame } from '../../../lib/frame-react';
import { driverEventsSlice } from '../../../store/slices/driverEventsSlice';
import { DriverEventAnalysis } from './DriverEventAnalysis';

import { DriverEventMap } from '../../DriverEvent/DriverEventMap';
import { DriverEventVideoList } from './DriverEventVideoList';

import { DriverEventId } from '../../../types/stein';

import { DriverEventTypeHeader } from './DriverEventTypeHeader';

import { Permission } from '../../Authorize/PermissionBlock';
import { DriverEventGodMode } from './DriverEventGodMode';
import { Stack } from '@mui/material';

export function DriverEventDetail({ eventId }: { eventId: DriverEventId }): React.ReactElement {
    const dispatch = useAppDispatch();

    const { useGetDriverEventQuery } = useFrame(SteinInternalApiClientToken);
    const { data, isFetching } = useGetDriverEventQuery({ id: eventId });
    const isLoaded = Boolean(!isFetching && data);

    const { primaryVideos, nearbyVideos, geofence, driverEvent: event } = data || {};

    React.useEffect(() => {
        if (isLoaded) {
            void dispatch(driverEventsSlice.actions.markAsSeen([eventId]));
        }
    }, [eventId, isLoaded]);

    return (
        <Stack spacing={2}>
            <span data-testid={'page-driver-event-list-detail'} />
            <DriverEventTypeHeader event={event} />
            <DriverEventAnalysis event={event} />

            <DriverEventVideoList
                event={event}
                primaryVideos={primaryVideos || []}
                nearbyVideos={nearbyVideos || []}
                isLoading={isFetching}
            />

            <DriverEventMap ping={event} geofence={geofence} />
            <Permission debugOnly>
                <DriverEventGodMode event={event} />
            </Permission>
        </Stack>
    );
}
