import * as TwilioVideo from 'twilio-video';

import { VehicleDevice, VideoCallId } from '../../../types/stein';
import { IntercomCallOpts, IntercomDeps, IntercomVideoCallAudioState } from '../intercom-types';
import { StartVideoCallResponse } from '../../../types/stein-internal-api';

const LOCAL_PARTICIPANT_VIDEO_ENABLED = false;

export function createInitialzieIntercomCallFn({
    emitter,
    log,
    store,
    stein,
    analytics,
}: IntercomDeps): (v: VehicleDevice) => Promise<StartVideoCallResponse | void> {
    function handleError(analytic: Record<string, unknown>) {
        return function (error: unknown): void {
            log.error('[IntercomService] error during video call', { error });
            analytics.track('intercom_error', { ...analytic, error });
        };
    }

    return async function initCall(vehicle: VehicleDevice): Promise<StartVideoCallResponse | void> {
        const analytic = {
            vehicle: vehicle.name,
        };

        analytics.track('initializing_intercom', analytic);
        emitter.emit('callPending');
        const callRes = await store
            .dispatch(
                stein.endpoints.startVideoCall.initiate(
                    { vehicleDeviceId: vehicle.id },
                    {
                        track: false,
                    },
                ),
            )
            .unwrap()
            .catch(handleError(analytic));

        if (!callRes) {
            emitter.emit('callChanged', null);
            analytics.track('intercom_failed', { ...analytic, reason: 'no response' });
            return;
        }
        return callRes;
    };
}

export function createGetCallCredentialsFn({
    emitter,
    log,
    store,
    stein,
    analytics,
}: IntercomDeps): (v: VideoCallId) => Promise<StartVideoCallResponse | void> {
    function handleError(analytic: Record<string, unknown>) {
        return function (error: unknown): void {
            log.error('[IntercomService] error joining video call', { error });
            analytics.track('intercom_error', { ...analytic, error });
        };
    }

    return async function fetchCredentials(callId: VideoCallId): Promise<StartVideoCallResponse | void> {
        const analytic = {
            call: callId,
        };

        analytics.track('fetching_intercom_credentials', analytic);
        emitter.emit('callPending');
        const callRes = await store
            .dispatch(stein.endpoints.fetchCallCredentials.initiate({ videoCallId: callId }))
            .unwrap()
            .catch(handleError(analytic));

        if (!callRes) {
            emitter.emit('callChanged', null);
            analytics.track('intercom_failed', { ...analytic, reason: 'no response' });
            return;
        }
        return callRes;
    };
}

export function createJoinIntercomFn({
    emitter,
    connect,
    analytics,
}: IntercomDeps): (v: StartVideoCallResponse, opts: IntercomCallOpts) => Promise<void> {
    return async function joinIntercomCall(call: StartVideoCallResponse, opts: IntercomCallOpts): Promise<void> {
        const videoTracks: TwilioVideo.RemoteVideoTrack[] = [];
        const audioTracks: TwilioVideo.RemoteAudioTrack[] = [];

        const analytic = {
            room: call.room,
            ...opts,
        };

        let audio: TwilioVideo.LocalAudioTrack | null = null;
        const room = await connect(call.callerAccessToken, {
            name: call.room,
            video: LOCAL_PARTICIPANT_VIDEO_ENABLED,
            audio: opts.broadcastAudio,
        });

        function getAudioState(): IntercomVideoCallAudioState {
            if (!room.localParticipant.audioTracks.size) {
                return 'disconnected';
            }
            const isMuted = !Array.from(room.localParticipant.audioTracks.values()).some((a) => a.isTrackEnabled);
            return isMuted ? 'muted' : 'unmuted';
        }

        // istanbul ignore next
        async function joinAudio(): Promise<void> {
            analytics.track('intercom_join_local_audio', analytic);

            // istanbul ignore next
            audio = await TwilioVideo.createLocalAudioTrack({ name: 'local-audio' });
            // istanbul ignore next
            await room.localParticipant.publishTrack(audio);
            // istanbul ignore next
            handleAnyChange();
        }

        function handleAnyChange(): void {
            const audioState = getAudioState();
            emitter.emit('callChanged', {
                audioState,
                vehicleSlug: call.vehicleSlug,
                toggleMute: function toggleMute() {
                    if (audioState === 'muted') {
                        analytics.track('intercom_muted', analytic);

                        room.localParticipant.audioTracks.forEach((p) => p.track.enable());
                        handleAnyChange();
                    } else if (audioState === 'unmuted') {
                        analytics.track('intercom_unmuted', analytic);

                        room.localParticipant.audioTracks.forEach((p) => p.track.disable());
                        handleAnyChange();
                    }
                },

                joinAudio: joinAudio,
                disconnect: function disconnect() {
                    return room.disconnect();
                },
                audioTracks,
                videoTracks,
            });
        }

        function addTrack<T extends TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack>(track: T): void {
            // istanbul ignore else
            if (track.kind === 'audio') {
                audioTracks.push(track);
            } else if (opts.joinVideo) {
                videoTracks.push(track);
            }
        }
        function removeTrack<T extends TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack>(track: T): void {
            // istanbul ignore else
            if (track.kind === 'audio') {
                const idx = audioTracks.indexOf(track);
                // istanbul ignore next
                if (idx >= 0) {
                    audioTracks.splice(idx, 1);
                }
            } else if (opts.joinVideo) {
                const idx = videoTracks.indexOf(track);
                // istanbul ignore next
                if (idx >= 0) {
                    videoTracks.splice(idx, 1);
                }
            }
        }

        // istanbul ignore next
        function trackSubscribed(t: TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack): void {
            // istanbul ignore next
            addTrack(t);
            // istanbul ignore next
            handleAnyChange();
        }
        // istanbul ignore next
        function trackUnsubscribed(t: TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack): void {
            // istanbul ignore next
            removeTrack(t);
            // istanbul ignore next
            handleAnyChange();
        }

        function handleParticipantConnected(p: TwilioVideo.RemoteParticipant): void {
            getTracks(p.audioTracks).forEach(addTrack);
            getTracks(p.videoTracks).forEach(addTrack);
            handleAnyChange();
            analytics.track('intercom_participant_connected', analytic);

            p.on('trackSubscribed', trackSubscribed);
            p.on('trackUnsubscribed', trackUnsubscribed);
        }

        function handleParticipantDisconnected(p: TwilioVideo.RemoteParticipant): void {
            getTracks(p.audioTracks).forEach(removeTrack);
            getTracks(p.videoTracks).forEach(removeTrack);
            p.off('trackSubscribed', trackSubscribed);
            p.off('trackUnsubscribed', trackUnsubscribed);
            handleAnyChange();
            analytics.track('intercom_participant_disconnected', analytic);

            if (!room.participants.size) {
                room.disconnect();
            }
        }

        room.participants.forEach(handleParticipantConnected);
        room.on('participantConnected', handleParticipantConnected);
        room.on('participantDisconnected', handleParticipantDisconnected);
        room.on('disconnected', function roomDisconnected() {
            audio && /* istanbul ignore next */ audio.stop();
            analytics.track('intercom_disconnected', analytic);

            emitter.emit('callChanged', null);
        });

        // istanbul ignore if
        if (opts.broadcastAudio) {
            try {
                await joinAudio();
            } catch (e) {}
        }

        handleAnyChange();
    };
}

type TrackPublication = TwilioVideo.RemoteAudioTrackPublication | TwilioVideo.RemoteVideoTrackPublication;

function getTracks<T extends TrackPublication>(trackMap: Map<string, T>): Exclude<T['track'], null>[] {
    const tracks: Exclude<T['track'], null>[] = [];

    trackMap.forEach((publication) => {
        // @ts-expect-error this should work
        publication.track && tracks.push(publication.track);
    });

    return tracks;
}
