import { Button, Stack, TextField, Tooltip } from '@mui/material';
import * as React from 'react';

import { AppIconPhone, AppIconSend, AppIconVideoCall } from '../../AppIcons';
import { CalloutBox, CalloutSkeleton } from '../../material/CalloutBox';
import { VehicleDevice } from '../../../types/stein';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { useIntercom } from '../../../services/app-intercom-service/use-intercom';
import { isVehicleOnline } from '../../Vehicle/vehicle_utils';
import { Permission } from '../../Authorize/PermissionBlock';
import LoadingButton from '@mui/lab/LoadingButton';
import { noop } from 'lodash';

type VehicleDeviceContactBoxProps = {
    vehicle: VehicleDevice | undefined;
};

export function VehicleDeviceContactBox({ vehicle }: VehicleDeviceContactBoxProps): React.ReactElement {
    const { call, intercom } = useIntercom();
    const callInProgress = Boolean(call);

    // istanbul ignore next
    if (!vehicle) {
        // istanbul ignore next
        return <CalloutSkeleton name="Contact" />;
    }
    const isOnline = isVehicleOnline(vehicle);
    return (
        <CalloutBox name="Contact">
            <Stack spacing={1.5}>
                <Permission auth={{ subject: 'VideoCall', action: 'voice_call' }}>
                    <DisabledTooltip callInProgress={callInProgress} isOffline={!isOnline}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<AppIconPhone />}
                            onClick={() => intercom.startCall(vehicle, { joinVideo: false, broadcastAudio: true })}
                            disabled={callInProgress || !isOnline}
                        >
                            {callInProgress ? /*istanbul ignore next*/ 'Call in Progress' : 'Intercom'}
                        </Button>
                    </DisabledTooltip>
                </Permission>
                <Permission auth={{ subject: 'VideoCall', action: 'live_stream' }}>
                    <LiveStream vehicle={vehicle} />
                </Permission>
                {/* <Permission auth={{ subject: 'VehicleDevice', action: 'download_video_call_recordings' }}>
                    <LiveStreamRecordings vehicle={vehicle} />
                </Permission> */}
                <Permission auth={{ subject: 'VehicleDevice', action: 'send_custom_announcement' }}>
                    <CustomAnnouncement vehicle={vehicle} />
                </Permission>
            </Stack>
        </CalloutBox>
    );
}

type ItemProps = {
    vehicle: VehicleDevice;
};

export function LiveStream({ vehicle }: ItemProps): React.ReactElement {
    const { call, intercom } = useIntercom();
    const callInProgress = Boolean(call);
    const online = isVehicleOnline(vehicle);

    return (
        <>
            <DisabledTooltip callInProgress={callInProgress} isOffline={!online}>
                <Button
                    fullWidth
                    variant="contained"
                    startIcon={<AppIconVideoCall />}
                    onClick={() => intercom.startCall(vehicle, { joinVideo: true, broadcastAudio: false })}
                    disabled={callInProgress || !online}
                >
                    {callInProgress ? /*istanbul ignore next*/ 'Call in Progress' : 'Live Stream'}
                </Button>
            </DisabledTooltip>
        </>
    );
}

function DisabledTooltip({
    callInProgress,
    isOffline,
    children,
}: {
    callInProgress: boolean;
    isOffline: boolean;
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <Tooltip
            title={
                isOffline
                    ? 'The vehicle is not online'
                    : callInProgress
                      ? /* istanbul ignore next */ 'A call is in progress'
                      : ''
            }
        >
            <span>{children}</span>
        </Tooltip>
    );
}

function CustomAnnouncement({ vehicle }: ItemProps): React.ReactElement {
    const [message, setMessage] = React.useState('');
    const { useCreatePushMutation } = useFrame(SteinInternalApiClientToken);
    const [createPush, { isLoading }] = useCreatePushMutation();

    function send(): void {
        createPush({
            vehicleDeviceId: vehicle.id,
            category: 'custom_announcement',
            announcementMessage: message,
        })
            .unwrap()
            .then(() => setMessage(''))
            .catch(noop);
    }

    return (
        <Tooltip title={'Send a one-time voice alert to the vehicle iPhone.'}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    send();
                }}
            >
                <Stack direction={'row'} spacing={1}>
                    <TextField
                        size={'small'}
                        label={'Voice Alert'}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        multiline
                    />
                    <LoadingButton loading={isLoading} type={'submit'} aria-label={'Send Custom Announcement'}>
                        <AppIconSend />
                    </LoadingButton>
                </Stack>
            </form>
        </Tooltip>
    );
}
