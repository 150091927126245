import * as React from 'react';
import {
    DataGridPro,
    DataGridProProps,
    GridCellParams,
    GridColDef,
    GridRowParams,
    MuiEvent,
    GridRow,
    GridRowProps,
} from '@mui/x-data-grid-pro';
import { appStyled, useAppTheme } from '../theme';
import { filterUndefinedAndNull } from '../utils/filter-utils';
import { InvisibleLink } from './InvisibleLink';
import { Checkbox, CheckboxProps, Stack } from '@mui/material';

export type DataGridRow = GridCellParams['row'];

export const AppDataGrid = appStyled(DataGridPro)(({ theme, rows, rowHeight }) => ({
    '&.MuiDataGrid-root': {
        maxHeight: 'calc(100vh - 175px)',
        backgroundColor: 'white',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader.godmode, &.MuiDataGrid-root .MuiDataGrid-cell.godmode': {
        borderColor: theme.colors.debugBlock.dev.border,
        borderWidth: '0 1px 0 1px',
        backgroundColor: theme.colors.debugBlock.dev.background,
        borderStyle: 'dashed',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper:focus-within, &.MuiDataGrid-root .MuiDataGrid-row:focus-within':
        {
            backgroundColor: theme.colors.datagrid.focus,
        },
    '&.MuiDataGrid-root .MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
        borderWidth: '0',
        display: 'flex',
        alignItems: 'center',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.success': {
        backgroundColor: theme.colors.datagrid.success,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.selected': {
        backgroundColor: theme.colors.datagrid.selected,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.disabled': {
        background: 'repeating-linear-gradient(45deg,#dddddd,#e3e3e3 10px,#dddddd 10px,#e3e3e3 20px)',
        boxShadow: `${theme.shadows[1]} inset`,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.error': {
        backgroundColor: theme.colors.datagrid.error,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row.pending': {
        backgroundColor: theme.colors.datagrid.pending,
    },
    '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent': rows?.length
        ? {
              backgroundSize: `100% ${rowHeight}px`,
              backgroundImage:
                  'linear-gradient(to right, rgba(224, 224, 224, 1) 1px, transparent 1px), linear-gradient(to bottom, rgba(224, 224, 224, 1) 1px, transparent 1px)',
          }
        : {},
}));

type AppDataGridProps = Required<Pick<DataGridProProps, 'columns' | 'rows'>> & {
    onRowChosen?: (row: DataGridRow) => void;
    rightPane?: React.ReactElement;

    isLoading?: boolean;
    isUninitialized?: boolean;
} & Omit<DataGridProProps, 'sx'>;

function getRowId(row: DataGridRow): string {
    return row.id;
}

const AppCheckbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
    function AppCheckboxForwardRef(props, ref): React.ReactElement {
        return (
            <Checkbox
                {...props}
                ref={ref}
                onClick={(e) => {
                    e.stopPropagation();
                    // istanbul ignore next
                    if (props?.onClick) {
                        // istanbul ignore next
                        props.onClick(e);
                    }
                }}
            />
        );
    },
);

const DATA_GRID_COMPONENTS = {
    row: AppGridRow,
    baseCheckbox: AppCheckbox,
};

function AppGridRow({ ...props }: React.HTMLAttributes<HTMLDivElement> & GridRowProps): React.ReactElement {
    let element = <GridRow {...props} />;
    if (props?.row?.url) {
        element = (
            <InvisibleLink to={props.row.url}>
                <GridRow {...props} />
            </InvisibleLink>
        );
    }

    return element;
}

export function debugDataGridColumn<T extends Record<string, unknown>>(
    column: GridColDef<T>,
    debugEnabled: boolean,
): GridColDef<T> | null {
    return debugEnabled
        ? {
              ...column,
              headerClassName: 'godmode',
              cellClassName: 'godmode',
          }
        : /* istanbul ignore next */ null;
}

export function processDataGridCollums<T extends Record<string, unknown>>(
    columns: Array<GridColDef<T> | null>,
): GridColDef<T>[] {
    return columns.filter(filterUndefinedAndNull).map((c: GridColDef<T>) => ({
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        resizable: false,
        pinnable: false,
        hideSortIcons: true,
        ...c,
    }));
}

export function DataGrid({
    rightPane,
    isLoading,
    isUninitialized,
    rows,
    onRowChosen,
    ...props
}: AppDataGridProps): React.ReactElement {
    const theme = useAppTheme();
    const onRowClick = React.useCallback(
        function onRowClickCallback(e: GridRowParams): void {
            onRowChosen && onRowChosen(e.row);
        },
        [onRowChosen],
    );

    const onCellKeyDown = React.useCallback(
        // istanbul ignore next
        function onCellKeyDownCallback(e: GridCellParams, evt: MuiEvent<React.KeyboardEvent<Element>>): void {
            if (evt.key === 'Enter') {
                onRowChosen && onRowChosen(e.row);
            }
        },
        [onRowChosen],
    );

    const componentProps = React.useMemo(
        () => ({
            columnsPanel: {
                tabIndex: 0,
            },
        }),
        [isLoading, isUninitialized],
    );

    return (
        <Stack
            height={'calc(100vh - 175px)'}
            direction={'row'}
            sx={{ backgroundColor: theme.colors.datagrid.selected }}
        >
            <AppDataGrid
                disableColumnReorder
                disableColumnSelector
                disableColumnResize
                disableMultipleColumnsSorting
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                rowHeight={36}
                onRowClick={onRowClick}
                onCellKeyDown={onCellKeyDown}
                loading={isLoading}
                rows={rows}
                getRowId={getRowId}
                slots={DATA_GRID_COMPONENTS}
                slotProps={componentProps}
                hideFooter
                {...props}
            />
            {rightPane}
        </Stack>
    );
}
