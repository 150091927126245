import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriverEventId } from '../../types/stein';
import { actionCableDriverEvent } from './shared-actions';

type EventMeta = {
    id: DriverEventId;
    isNew?: boolean;
};

export const driverEventAdapter = createEntityAdapter<EventMeta>({
    selectId: (e) => e.id,
});

export const driverEventsSlice = createSlice({
    name: 'driverEvents',
    initialState: driverEventAdapter.getInitialState(),
    reducers: {
        markAsSeen(state, action: PayloadAction<DriverEventId[]>) {
            driverEventAdapter.removeMany(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(actionCableDriverEvent, (s, a) => {
            driverEventAdapter.setOne(s, {
                id: a.payload.data.id,
                isNew: true,
            });
        });
    },
});
