import * as React from 'react';

import { AppIconPhone, AppIconVideoCall } from '../../AppIcons';
import { Chip, ListItemText, Stack } from '@mui/material';

import { useFrame } from '../../../lib/frame-react';
import { NotificationItemWrapper } from './internal/NotificationItemWrapper';

import { AppNotificationTypeIncomingCall } from '../notificationTypes';
import { IntercomServiceToken } from '../../../services/app-intercom-service';
import { VehicleChip } from '../../AppChip';
import { useSoundEffect } from '../../../hooks/use-sound-effect';
import { useAppSelector } from '../../../hooks/hooks';

function JoinCall({ notification }: NotificationItemIncomingCallProps): React.ReactElement {
    const intercom = useFrame(IntercomServiceToken);

    async function onJoin(): Promise<void> {
        await intercom.joinCall(notification.call.data.id);
    }

    return (
        <Chip
            icon={
                <div style={{ fontSize: '12px' }}>
                    <AppIconPhone />
                </div>
            }
            label="Join Call"
            variant="outlined"
            onClick={onJoin}
            size="small"
        />
    );
}

type NotificationItemIncomingCallProps = {
    notification: AppNotificationTypeIncomingCall;
};

export function NotificationItemIncomingCall({ notification }: NotificationItemIncomingCallProps): React.ReactElement {
    const userName = notification.call.data.dashboardUserName;
    const answeredAt = notification.call.data.answeredAt;
    const isAnswering = Boolean(userName && !answeredAt);
    const isAnswered = Boolean(answeredAt);
    const isEnded = Boolean(notification.call.data.endedAt);
    const { defaultSoundEffectId } = useAppSelector((s) => s.audio);
    const [effect, { state }] = useSoundEffect(defaultSoundEffectId);

    React.useEffect(() => {
        if (isAnswering || isAnswered || isEnded) {
            effect?.pause();
        } else {
            // istanbul ignore else
            if (state !== 'play') {
                effect?.loop();
                effect?.play();
            }
        }
    }, [isAnswering, isAnswered, isEnded, state, effect]);

    return (
        <NotificationItemWrapper
            autoAckMs={isEnded || isAnswered ? 1000 : undefined}
            icon={<AppIconVideoCall color={isAnswering ? 'green' : isAnswered ? 'grey' : 'red'} />}
        >
            <Stack spacing={1}>
                <ListItemText
                    primary={
                        <>
                            {`Call from `}
                            <VehicleChip vehicleSlugOrId={notification.call.data.vehicleDeviceId} size="small" />
                        </>
                    }
                    secondary={
                        isEnded ? (
                            'ended'
                        ) : isAnswering ? (
                            <>
                                <div>{userName}</div>
                                <div>is joining...</div>
                            </>
                        ) : isAnswered ? (
                            <>
                                <div>{userName}</div>
                                <div>has joined</div>
                            </>
                        ) : undefined
                    }
                />

                {isAnswered ? null : <JoinCall notification={notification} />}
            </Stack>
        </NotificationItemWrapper>
    );
}
