// This file is generated from config/config_items.yml by ./bin/gen_config_item_types
import * as Stein from './stein';
export const CONFIG_ITEM_PLATFORM_IOS = 0 as Stein.ConfigItemPlatform;
export const CONFIG_ITEM_PLATFORM_WEB = 1 as Stein.ConfigItemPlatform;
export type ConfigItemWebDistractionSoundEnabled = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'browser_notification_sound_enabled_distraction';
    boolValue: boolean;
};

export type ConfigItemWebEyeClosureSoundEnabled = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'browser_notification_sound_enabled_eye_closure';
    boolValue: boolean;
};

export type ConfigItemWebDurationThresholds = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'duration_thresholds';
    jsonValue: Stein.Thresholds;
};

export type ConfigItemWebAuditTagProfileWhitelist = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'audit_tag_profile_whitelist';
    jsonValue: string[];
};

export type ConfigItemWebDriverEventFilterPresets = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'driver_event_filter_presets';
    jsonValue: Stein.FilterPresetConfig;
};

export type ConfigItemWebDriverEventWebAlerts = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'driver_event_web_alerts';
    jsonValue: Stein.DriverEventWebAlerts;
};

export type ConfigItemFeatureAuditsEnabled = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'audits_tab_enabled';
    boolValue: boolean;
};

export type ConfigItemFeatureDriverEventListDetail = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'driver_event_list_detail';
    boolValue: boolean;
};

export type ConfigItemFeatureDiscardAllDriverEvents = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'discard_all_neglect_events';
    boolValue: boolean;
};

export type ConfigItemFeatureShowCabinAlertStatus = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'show_cabin_alert_status';
    boolValue: boolean;
};

export type ConfigItemFeatureGuidedAccessSupport = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'supports_autonomous_single_app_mode';
    boolValue: boolean;
};

export type ConfigItemFeatureAuditCustomMetadata = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'audit_custom_metadata';
    boolValue: boolean;
};

export type ConfigItemFeatureRemoteRestartDevice = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'remote_restart_device';
    boolValue: boolean;
};

export type ConfigItemInternalIntercomEnabled = {
    projectId: Stein.ProjectId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'intercom_enabled';
    boolValue: boolean;
};

export type ConfigItemWebResourcesURL = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'resources_url';
    stringValue: string;
};

export type ConfigItemWebSegmentAnalyticsConfiguration = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'analytics_config';
    jsonValue: Stein.AnalyticsConfig;
};

export type ConfigItemWebDefaultCustomRoleForNewMembers = {
    projectId?: Stein.ProjectId;
    platform?: Stein.ConfigItemPlatform;
    id: number;
    attributeKey: 'default_custom_role_id';
    integerValue: number;
};

export type ConfigItemIOSMediaExpirationSeconds = {
    projectId?: Stein.ProjectId;
    vehicleDeviceId?: Stein.VehicleDeviceId;
    regionId?: Stein.RegionId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'continuous_recording_media_expiration_seconds';
    floatValue: number;
};

export type ConfigItemIOSHarshManeuverSensitivity = {
    projectId?: Stein.ProjectId;
    vehicleDeviceId?: Stein.VehicleDeviceId;
    regionId?: Stein.RegionId;
    id: number;
    platform?: Stein.ConfigItemPlatform;
    attributeKey: 'harsh_maneuver_sensitivity';
    integerValue: number;
};
export type ConfigItemWeb =
    | ConfigItemWebDistractionSoundEnabled
    | ConfigItemWebEyeClosureSoundEnabled
    | ConfigItemWebDurationThresholds
    | ConfigItemWebAuditTagProfileWhitelist
    | ConfigItemWebDriverEventFilterPresets
    | ConfigItemWebDriverEventWebAlerts
    | ConfigItemWebResourcesURL
    | ConfigItemWebSegmentAnalyticsConfiguration
    | ConfigItemWebDefaultCustomRoleForNewMembers;
export type ConfigItemIOS = ConfigItemIOSMediaExpirationSeconds | ConfigItemIOSHarshManeuverSensitivity;
export type ConfigItemInternal = ConfigItemInternalIntercomEnabled;
export type ConfigItemFeature =
    | ConfigItemFeatureAuditsEnabled
    | ConfigItemFeatureDriverEventListDetail
    | ConfigItemFeatureDiscardAllDriverEvents
    | ConfigItemFeatureShowCabinAlertStatus
    | ConfigItemFeatureGuidedAccessSupport
    | ConfigItemFeatureAuditCustomMetadata
    | ConfigItemFeatureRemoteRestartDevice;
export type ConfigItem = ConfigItemWeb | ConfigItemIOS | ConfigItemInternal | ConfigItemFeature;
export type ConfigItemWebKey = ConfigItemWeb['attributeKey'];
export type ConfigItemIOSKey = ConfigItemIOS['attributeKey'];
export type ConfigItemInternalKey = ConfigItemInternal['attributeKey'];
export type ConfigItemFeatureKey = ConfigItemFeature['attributeKey'];
export type ConfigItemKey = ConfigItem['attributeKey'];
export type ConfigItemForKey = {
    browser_notification_sound_enabled_distraction: ConfigItemWebDistractionSoundEnabled;
    browser_notification_sound_enabled_eye_closure: ConfigItemWebEyeClosureSoundEnabled;
    duration_thresholds: ConfigItemWebDurationThresholds;
    audit_tag_profile_whitelist: ConfigItemWebAuditTagProfileWhitelist;
    driver_event_filter_presets: ConfigItemWebDriverEventFilterPresets;
    driver_event_web_alerts: ConfigItemWebDriverEventWebAlerts;
    audits_tab_enabled: ConfigItemFeatureAuditsEnabled;
    driver_event_list_detail: ConfigItemFeatureDriverEventListDetail;
    discard_all_neglect_events: ConfigItemFeatureDiscardAllDriverEvents;
    show_cabin_alert_status: ConfigItemFeatureShowCabinAlertStatus;
    supports_autonomous_single_app_mode: ConfigItemFeatureGuidedAccessSupport;
    audit_custom_metadata: ConfigItemFeatureAuditCustomMetadata;
    remote_restart_device: ConfigItemFeatureRemoteRestartDevice;
    intercom_enabled: ConfigItemInternalIntercomEnabled;
    resources_url: ConfigItemWebResourcesURL;
    analytics_config: ConfigItemWebSegmentAnalyticsConfiguration;
    default_custom_role_id: ConfigItemWebDefaultCustomRoleForNewMembers;
    continuous_recording_media_expiration_seconds: ConfigItemIOSMediaExpirationSeconds;
    harsh_maneuver_sensitivity: ConfigItemIOSHarshManeuverSensitivity;
};
