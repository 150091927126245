import * as React from 'react';
import { AppNotification } from '../notificationTypes';
import { NotificationItemArchive } from './NotificationItemArchive';
import { NotificationItemEvent } from './NotificationItemEvent';
import { NotificationItemGeneric } from './NotificationItemGeneric';
import { NotificationItemPush } from './NotificationItemPush';
import { NotificationItemPushMulti } from './NotificationItemPushMulti';
import { NotificationItemIncomingCall } from './NotificationItemIncomingCall';

type NotificationItemSwitchProps = {
    notification: AppNotification;
};

function NotificationItemSwitch({ notification }: NotificationItemSwitchProps): React.ReactElement {
    switch (notification.type) {
        case 'event':
            return <NotificationItemEvent notification={notification} />;
        case 'archive':
            return <NotificationItemArchive notification={notification} />;
        case 'push':
            return <NotificationItemPush notification={notification} />;
        case 'pushMulti':
            return <NotificationItemPushMulti notification={notification} />;
        case 'generic':
            return <NotificationItemGeneric notification={notification} />;
        case 'incoming-call':
            return <NotificationItemIncomingCall notification={notification} />;
    }
}

type NotificationItemProps = {
    notification: AppNotification;
    onDismiss?: DismissFunc;
    onAck?: DismissFunc;
    isSnackbar?: boolean;
};

export function NotificationItem({
    notification,
    onDismiss,
    isSnackbar,
    onAck,
}: NotificationItemProps): React.ReactElement {
    return (
        <context.Provider value={{ dismiss: onDismiss, notification, isSnackbar, ack: onAck }}>
            <NotificationItemSwitch notification={notification} />
        </context.Provider>
    );
}

type DismissFunc = () => void;

type NotificationItemContext = {
    dismiss?: DismissFunc;
    ack?: DismissFunc;
    notification: AppNotification;
    isSnackbar: boolean;
};

const context = React.createContext<Partial<NotificationItemContext>>({});

export function useNotificationItemContext(): NotificationItemContext {
    return React.useContext(context) as NotificationItemContext;
}
