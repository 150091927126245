import { NotificationListenerDeps } from './notification-listener-types';
import { notificationSlice } from '../../store/slices/notificationSlice';
import { actionCableIncomingCall } from '../../store/actions';
import { selectNotificationById } from '../../store/selectors';

import { AnyAction } from 'redux';
import { AppNotificationId } from '../../components/Notifications/notificationTypes';
import { ActionCableVideoCall } from '../../types/stein';

function eventNotificationId(payload: ActionCableVideoCall): AppNotificationId {
    return `video-call-${payload.data.id}` as AppNotificationId;
}

export function notificationListenerVideoCall(action: AnyAction, deps: NotificationListenerDeps): void {
    const { store } = deps;

    if (actionCableIncomingCall.match(action)) {
        const state = store.getState();

        const notificationId = eventNotificationId(action.payload);
        const existingCall = selectNotificationById(state, notificationId);

        // istanbul ignore next
        if (existingCall) {
            store.dispatch(
                notificationSlice.actions.updateNotification({
                    id: eventNotificationId(action.payload),
                    call: action.payload,
                }),
            );
        } else {
            store.dispatch(
                notificationSlice.actions.createNotification({
                    id: notificationId,
                    type: 'incoming-call',
                    call: action.payload,
                }),
            );
        }
    }
}
