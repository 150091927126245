import { SteinAPI as SteinInternalAPI } from '../../clients/stein-internal-api';
import EventEmitter from '@foxify/events';

import { FrameLogger } from '../../lib/frame-tokens';
import { AppStore } from '../../store';
import { VehicleDevice, VehicleDeviceSlug, VideoCallId } from '../../types/stein';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import { connect } from 'twilio-video';
import { Device } from '@twilio/voice-sdk';
import { AppAnalytics } from '../../lib/frame-analytics';
import { StartVideoCallResponse } from '../../types/stein-internal-api';

export type IntercomVideoCallAudioState = 'disconnected' | 'muted' | 'unmuted';

export type IntercomCall = Readonly<{
    toggleMute: () => void;
    disconnect: () => void;
    vehicleSlug: VehicleDeviceSlug;
    audioTracks: readonly AudioTrack[];
    videoTracks: readonly VideoTrack[];
    joinAudio(): void;
    audioState: IntercomVideoCallAudioState;
}>;

type IntercomEvents = {
    callChanged: (call: IntercomCall | null) => void;
    callPending: () => void;
};

export type IntercomEmitter = EventEmitter<IntercomEvents>;

export type IntercomCallOpts = {
    joinVideo: boolean;
    broadcastAudio: boolean;
};

export type IntercomService = {
    startCall: (vehicle: VehicleDevice, opts: IntercomCallOpts) => Promise<StartVideoCallResponse | void>;
    joinCall: (call: VideoCallId) => Promise<void>;
    emitter: IntercomEmitter;
};

export function createIntercomEmitter(): IntercomEmitter {
    return new EventEmitter<IntercomEvents>();
}

export type TwilioAudioDevice = typeof Device;
export type TwilioVideoConnect = typeof connect;

export type IntercomDeps = {
    store: AppStore;
    log: FrameLogger;
    analytics: AppAnalytics;
    emitter: IntercomEmitter;
    stein: SteinInternalAPI;
    AudioDevice: TwilioAudioDevice;
    connect: TwilioVideoConnect;
};

export type AudioTrack = RemoteAudioTrack;
export type VideoTrack = RemoteVideoTrack;
