import * as React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../hooks/hooks';
import { selectAllProjects, selectDebugEnabled, selectProjectById, selectVehicleById } from '../store/selectors';
import {
    AbilityActionsForSubject,
    AbilitySubject,
    AuditSlug,
    DriverEventId,
    ProjectSlug,
    VehicleDeviceSlug,
} from '../types/stein';
import { useHasPermission } from './Authorize/Authorize';
import { Error404 } from './pages/Error404';
import { ErrorUnimplemented } from './pages/ErrorUnimplemented';
import { ErrorUnauthorized } from './pages/ErrorUnauthorized';
import { DriverEventShow } from './pages/DriverEventShow';
import { DriverEventList } from './pages/DriverEventList';
import {
    urlAudit,
    urlAuditList,
    urlDriverEventList,
    urlDriverEventShow,
    urlMap,
    urlProject,
    urlSettings,
    urlVehicleDeviceList,
    urlVehicleDeviceShow,
    urlVehicleDeviceTimeline,
    URL_GOD_MODE,
    URL_GOD_MODE_ANALYTICS,
    URL_SUPPORT,
    URL_UNDER_CONSTRUCTION,
    urlAppInstallList,
    urlConfigItemsList,
    urlGeoSearch,
    urlMonitor,
} from '../utils/internal-url-utils';
import { VehicleDeviceList } from './pages/VehicleDeviceList';
import { VehicleDeviceShow } from './pages/VehicleDeviceShow';
import { MapPage } from './pages/MapPage';
import { SettingsPage } from './pages/Settings';
import { SupportPage } from './pages/SupportPage';
import { AuditIndex } from './pages/AuditList/AuditList';
import { AuditShow } from './pages/AuditShow/AuditShow';
import { ConfigItemFeatureKey } from '../types/stein.config_items';
import { useHasFeatureOpt } from './Authorize/Feature';
import { appStyled, useAppTheme } from '../theme';
import { VehicleTimelineShowPage } from './pages/VehicleTimelineShow/VehicleTimelineShowPage';
import { ActiveProjectProvider } from '../hooks/use-active-project';
import { ErrorPage } from './pages/ErrorPage';
import { GodModeAnalyticsPage } from './pages/GodMode/GodModeAnalytics';
import { GodModeDashboardPage } from './pages/GodMode/GodModeDashboard';
import { AppInstallList } from './pages/AppInstallList';
import { ConfigItemListPage } from './pages/ConfigItemList/ConfigItemListPage';
import { Box } from '@mui/system';
import { LoadingPage } from './RailsContextLoader';
import { useFrame } from '../lib/frame-react';
import { SteinInternalApiClientToken } from '../clients/stein-internal-api';
import { GeoSearchPage } from './pages/GeoSearch';
import { MonitoringPage } from './pages/Monitoring';
import { Sandbox } from './pages/Sandbox/Sandbox';

const RouteDebugBanner = appStyled('div')<{ color: string }>(({ color, theme }) => ({
    width: '100%',
    display: 'flex',
    fontStyle: 'italic',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.typography.caption,
    backgroundColor: color,
}));

// URL placeholder variables that will get populated
const P_PROJECT = ':projectSlug' as ProjectSlug;
//@ts-expect-error DriverEventId expects a number but we're giving it a string here
const P_DRIVER_EVENT = ':driverEventId' as DriverEventId;
const P_VEHICLE = ':vehicleDeviceSlug' as VehicleDeviceSlug;
const P_AUDIT = ':auditSlug' as AuditSlug;

function DriverEventRedirect({ driverEventId }: { driverEventId: DriverEventId }): React.ReactElement {
    const { useGetDriverEventQuery } = useFrame(SteinInternalApiClientToken);
    const { data, isLoading } = useGetDriverEventQuery({ id: driverEventId });
    const vehicle = useAppSelector((s) => selectVehicleById(s, data?.driverEvent?.vehicleDeviceId));
    const project = useAppSelector((s) => selectProjectById(s, vehicle?.projectId));

    if (project && data?.driverEvent) {
        return <Redirect to={urlDriverEventShow(project, data?.driverEvent)} />;
    }
    return !isLoading ? /*istanbul ignore next*/ <Error404 /> : <LoadingPage />;
}

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <AppRoute exact path="/" component={ActiveProjectRedirect} />
            <AppRoute exact path="/" component={ActiveProjectRedirect} />
            <AppRoute exact path={'/sandbox'} component={Sandbox} />
            <AppRoute exact path={'/projects'} component={ActiveProjectRedirect} />
            <Redirect exact from={urlProject(P_PROJECT)} to={urlDriverEventList(P_PROJECT)} />

            {/*START REVERSE COMPATABILIY WITH V1 DASHBOARD*/}
            <Redirect
                exact
                from="/projects/:projectSlug/driver_events/:vehicleDeviceId/:eventId"
                to="/projects/:projectSlug/driver_events/:eventId"
            />
            <Redirect exact from="/projects/:projectSlug/edit" to="/projects/:projectSlug/settings" />
            <Redirect exact from="/projects/:projectSlug/memberships" to={urlSettings(P_PROJECT, 'memberships')} />
            <Redirect exact from="/projects/:projectSlug/roles" to={urlSettings(P_PROJECT, 'roles')} />
            <Redirect
                exact
                from="/projects/:projectSlug/project_notifications"
                to={urlSettings(P_PROJECT, 'notifications')}
            />
            <Redirect exact from="/projects/:projectSlug/device_settings" to={urlSettings(P_PROJECT, 'device')} />
            <Redirect exact from="/projects/:projectSlug/advanced_settings" to={urlSettings(P_PROJECT, 'advanced')} />
            <Route
                path="/driver_events/:driverEventId"
                render={
                    // istanbul ignore next
                    function ({ match }) {
                        return (
                            <DriverEventRedirect
                                driverEventId={parseInt(match.params.driverEventId) as DriverEventId}
                            />
                        );
                    }
                }
            />
            <Route
                path="/v2/*"
                render={
                    // istanbul ignore next
                    function ({ location }) {
                        // istanbul ignore next
                        return <Redirect to={`${location.pathname.replace('/v2/', '/')}`} />;
                    }
                }
            />
            {/* END REVERSE COMPATABILIY WITH V1 DASHBOARD*/}

            <AppRoute exact path={URL_SUPPORT} component={SupportPage} />
            <AppRoute
                exact
                path={URL_UNDER_CONSTRUCTION}
                component={function UnderConstruction(): React.ReactElement {
                    return <div>{'Under Construction'}</div>;
                }}
                underConstruction
            />
            <AppRoute exact debugOnly path={URL_GOD_MODE} component={GodModeDashboardPage} />
            <AppRoute exact debugOnly path={URL_GOD_MODE_ANALYTICS} component={GodModeAnalyticsPage} />

            <Route path={urlProject(P_PROJECT)}>
                <ActiveProjectProvider>
                    <Switch>
                        <AppRoute
                            exact
                            path={urlMap(P_PROJECT)}
                            component={MapPage}
                            subject={'VehicleDevice'}
                            action={'show_map'}
                        />
                        <AppRoute
                            exact
                            path={urlGeoSearch(P_PROJECT)}
                            component={GeoSearchPage}
                            subject={'VehicleDevice'}
                            action={'geosearch'}
                        />
                        <AppRoute
                            exact
                            path={urlMonitor(P_PROJECT)}
                            component={MonitoringPage}
                            subject={'VideoCall'}
                            action={'live_stream'}
                        />
                        <AppRoute path={urlSettings(P_PROJECT)} component={SettingsPage} />
                        <AppRoute
                            exact
                            path={urlDriverEventList(P_PROJECT)}
                            component={DriverEventList}
                            subject={'DriverEvent'}
                        />
                        <AppRoute
                            exact
                            path={urlDriverEventShow(P_PROJECT, P_DRIVER_EVENT)}
                            component={DriverEventShow}
                            subject={'DriverEvent'}
                        />

                        <AppRoute
                            exact
                            path={urlVehicleDeviceList(P_PROJECT)}
                            component={VehicleDeviceList}
                            subject={'VehicleDevice'}
                        />

                        <AppRoute
                            exact
                            path={urlVehicleDeviceShow(P_PROJECT, P_VEHICLE)}
                            component={VehicleDeviceShow}
                            subject={'VehicleDevice'}
                        />

                        <AppRoute
                            exact
                            path={urlAppInstallList(P_PROJECT)}
                            component={AppInstallList}
                            subject={'AppInstall'}
                            debugOnly
                        />

                        <AppRoute
                            exact
                            path={urlVehicleDeviceTimeline(P_PROJECT, P_VEHICLE)}
                            component={VehicleTimelineShowPage}
                            subject={'VehicleDevice'}
                            action={'view_timeline'}
                        />

                        <AppRoute exact path={urlConfigItemsList(P_PROJECT)} component={ConfigItemListPage} debugOnly />

                        <AppRoute
                            exact
                            path={urlAuditList(P_PROJECT)}
                            component={AuditIndex}
                            subject={'Audit'}
                            feature={'audits_tab_enabled'}
                            debugOnly
                        />
                        <AppRoute
                            exact
                            path={urlAudit(P_PROJECT, P_AUDIT)}
                            component={AuditShow}
                            subject={'Audit'}
                            feature={'audits_tab_enabled'}
                            debugOnly
                        />
                        <Route path="*" component={Error404} />
                    </Switch>
                </ActiveProjectProvider>
            </Route>
            <Route path="*" component={Error404} />
        </Switch>
    );
}

function ActiveProjectRedirect(): React.ReactElement {
    const projects = useAppSelector(selectAllProjects);
    const project = Object.values(projects)[0];
    const theme = useAppTheme();

    if (project && project.slug) {
        return <Redirect to={urlProject(project)} />;
    }
    return (
        <ErrorPage
            type={'not-found'}
            content={
                <Box
                    sx={{
                        padding: 2,
                        marginTop: 2,
                        maxWidth: '600px',
                        width: '80%',
                        textAlign: 'center',
                        backgroundColor: theme.palette.info.main,
                        color: theme.palette.info.contrastText,
                        borderRadius: '10px',
                    }}
                >
                    <h2 style={{ marginTop: 0 }}>Welcome!</h2>
                    Looks like we still need to set up a project for your account.{' '}
                    <Link style={{ color: 'inherit' }} to={URL_SUPPORT}>
                        Contact us
                    </Link>{' '}
                    to get the ball rolling.
                </Box>
            }
        ></ErrorPage>
    );
}

type AppRouteProps<T extends AbilitySubject> = React.ComponentProps<typeof Route> & {
    debugOnly?: boolean;
    underConstruction?: boolean;
    feature?: ConfigItemFeatureKey;
    subject?: T;
    action?: AbilityActionsForSubject<T>;
};

export function AppRoute<T extends AbilitySubject>({
    debugOnly,
    underConstruction,
    feature,
    subject,
    ...props
}: AppRouteProps<T>): React.ReactElement {
    const debug = useAppSelector(selectDebugEnabled);
    const hasFeature = useHasFeatureOpt(feature);
    const theme = useAppTheme();

    // istanbul ignore next
    if (/* istanbul ignore next */ debugOnly && !debug) {
        // istanbul ignore next
        return <ErrorPage type={'not-found'} content={'Not Found'} />;
    } else if (!hasFeature) {
        return <ErrorPage type={'not-found'} content={'Not Found'} />;
    } else if (underConstruction && /* istanbul ignore next */ !debug) {
        // istanbul ignore next
        return <ErrorUnimplemented />;
    }

    const route = subject ? <AppRouteAuthorized subject={subject} {...props} /> : <Route {...props} />;

    // istanbul ignore next
    if (debug) {
        // istanbul ignore next
        return (
            <>
                {debugOnly ? (
                    <RouteDebugBanner
                        color={theme.colors.debugBlock.dev.background}
                    >{`Route is debug only`}</RouteDebugBanner>
                ) : null}
                {feature ? (
                    <RouteDebugBanner
                        color={theme.colors.debugBlock.feature.background}
                    >{`Route requires feature: ${feature}`}</RouteDebugBanner>
                ) : null}
                {route}
            </>
        );
    }
    return route;
}

type AppRouteAuthorizedProps<T extends AbilitySubject> = React.ComponentProps<typeof Route> & {
    subject: T;
    action?: AbilityActionsForSubject<T>;
};

function AppRouteAuthorized<T extends AbilitySubject>({
    subject,
    action,
    ...props
}: AppRouteAuthorizedProps<T>): React.ReactElement {
    const debugEnabled = useAppSelector(selectDebugEnabled);

    const path = '' + props.path;
    const useAction = (
        action ? action : path.split('/').pop()?.startsWith(':') ? 'show' : 'list'
    ) as AbilityActionsForSubject<T>;

    const hasPermission = useHasPermission(subject, useAction);
    const theme = useAppTheme();

    if (!hasPermission) {
        return <ErrorUnauthorized />;
    }

    if (debugEnabled) {
        return (
            <>
                <RouteDebugBanner
                    color={theme.colors.debugBlock.auth.background}
                >{`Route requires ability: ${subject}:${useAction}`}</RouteDebugBanner>
                <Route {...props} />
            </>
        );
    }
    return <Route {...props} />;
}
